<template>
  <b-container class="col">
    <b-card>
      <div class="d-flex align-items-center">
        <b-form-group label="Order Date From" class="d-flex">
          <flat-pickr
            v-model="date_start"
            :config="{
              dateFormat: 'Y-m-d',
              maxDate: date_end,
            }"
            class="form-control font-small-3"
            placeholder="Choose Date"
            readonly="readonly"
          />
        </b-form-group>
        <h5 class="mx-1 mt-1 d-flex align-items-center">to</h5>
        <b-form-group label="Order Date To" class="d-flex">
          <flat-pickr
            v-model="date_end"
            :config="{
              dateFormat: 'Y-m-d',
              minDate: date_start,
            }"
            class="form-control font-small-3"
            placeholder="Choose Date"
            readonly="readonly"
          />
        </b-form-group>
        <b-button
          @click="getallreports()"
          variant="primary"
          class="btn-tour-skip mx-1 mt-1"
        >
          <span class="mr-25 align-middle">Get Orders</span>
        </b-button>
        <b-button
          @click="ExportToExcel()"
          variant="outline-primary"
          class="btn-tour-skip mt-1"
        >
          <span class="mr-25 align-middle">Extract Orders</span>
        </b-button>
      </div>
      <MyTable :columns="columns" :rows="rows" :isLoading="isLoading">
        <template v-slot="{ props }">
          <span v-if="props.column.field === 'id'">
            <b-button
              v-if="props.row.shipment === 'Outlet'"
              variant="gradient-primary"
              block
              @click="goToOrderSummary(props.row.id)"
            >
              {{ props.row.id }}
            </b-button>
            <b-button
              v-else-if="props.row.shipment === 'Singapore'"
              variant="gradient-primary"
              block
              @click="goToOrderSummary(props.row.id)"
            >
              {{ props.row.id }}
            </b-button>
            <b-button
              v-else
              variant="gradient-primary"
              block
              @click="goToOrderSummary(props.row.id)"
            >
              {{ props.row.id }}
            </b-button>
          </span>

          <span v-else-if="props.column.field === 'created_at'">
            {{ $helpers.formatDate(props.row.created_at) }}
          </span>

          <span v-else-if="props.column.field === 'delivery_date'">
            {{
              props.row.delivery_date
                ? $helpers.formatDate(props.row.delivery_date)
                : "-"
            }}
          </span>

          <span v-else-if="props.column.field === 'shipment'">
            {{
              props.row.shipment === "Outlet" ? "Outlet" : props.row.shipment
            }}
          </span>

          <span v-else-if="props.column.field == 'status'">
            <b-button
              v-if="props.row.status === 'Active'"
              block
              variant="light-purple"
            >
              {{ props.row.status }}
            </b-button>
            <b-button
              v-else-if="props.row.status === 'Assigned'"
              block
              variant="dark-purple"
            >
              {{ props.row.status }}
            </b-button>
            <b-button
              v-else-if="props.row.status === 'In Warehouse'"
              block
              variant="light-blue"
            >
              {{ props.row.status }}
            </b-button>
            <b-button
              v-else-if="props.row.status === 'In Transit'"
              block
              variant="yellow"
            >
              {{ props.row.status }}
            </b-button>
            <b-button
              v-else-if="props.row.status === 'Out For Delivery'"
              block
              variant="purple"
            >
              {{ props.row.status }}
            </b-button>
            <b-button
              v-else-if="props.row.status === 'Delivered'"
              block
              variant="turquoise"
            >
              {{ props.row.status }}
            </b-button>
            <b-button
              v-else-if="props.row.status === 'Close Store'"
              block
              variant="maroon"
            >
              {{ props.row.status }}
            </b-button>
            <b-button v-else block variant="orange">
              {{ props.row.status }}
            </b-button>
          </span>

          <span v-else-if="props.column.field === 'tax'">
            {{ props.row.tax ? props.row.tax : 0 }}
          </span>

          <span v-else-if="props.column.field === 'sub_total'">
            {{
              props.row.shipment !== "DHL" ||
              props.row.shipment !== "Distributor"
                ? $helpers.formatTotal(props.row.sub_total)
                : $helpers.formatTotal(props.row.grand_total)
            }}
          </span>

          <span v-else-if="props.column.field === 'grand_total'">
            {{ $helpers.formatTotal(props.row.grand_total) }}
          </span>

          <span v-else-if="props.column.field === 'costing.costing'">
            {{ $helpers.formatTotal(props.row.cost) }}
          </span>

          <span v-else-if="props.column.field === 'costing.commission'">
            {{ $helpers.formatTotal(props.row.commission) }}
          </span>

          <span v-else-if="props.column.field === 'costing.Outlet_profit'">
            {{ $helpers.formatTotal(props.row.profit) }}
          </span>

          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </MyTable>
    </b-card>
  </b-container>
</template>

<script>
import MyTable from "@/views/components/MyTable.vue";
import flatPickr from "vue-flatpickr-component";
import exportFromJSON from "export-from-json";

export default {
  components: {
    MyTable,
    flatPickr,
  },

  data() {
    return {
      rows: [],
      date_end: new Date().toISOString().split("T")[0],
      date_start: new Date(Date.now() - 172800000).toISOString().split("T")[0],
      isLoading: false,
      date: new Date().toISOString(),
      columns: [
        {
          label: "Order ID",
          field: "id",
          tdClass: "text-center align-middle",
        },
        {
          label: "Delivery ID",
          field: "route_id",
          tdClass: "text-center align-middle",
        },
        {
          label: "Order Date",
          field: "created_at",
          tdClass: "text-center align-middle",
        },
        {
          label: "Delivery Date",
          field: "delivery_date",
          tdClass: "text-center align-middle",
        },
        {
          label: "Shipment",
          field: "shipment",
          tdClass: "text-center align-middle",
        },
        {
          label: "Sender's Name",
          field: "sender_name",
          tdClass: "text-center align-middle",
        },
        {
          label: "Recipient's Name",
          field: "recipient_name",
          tdClass: "text-center align-middle",
        },
        {
          label: "Origin Postcode",
          field: "sender_postcode",
          tdClass: "text-center align-middle",
        },
        {
          label: "Destination Postcode",
          field: "destination_postcode",
          tdClass: "text-center align-middle",
        },
        {
          label: "QTY",
          field: "qty",
          tdClass: "text-center align-middle",
        },
        {
          label: "Subtotal",
          field: "sub_total",
          tdClass: "text-center align-middle",
        },
        {
          label: "Tax",
          field: "tax",
          tdClass: "text-center align-middle",
        },
        {
          label: "Remarks",
          field: "remarks",
          tdClass: "text-center align-middle",
        },
        {
          label: "Status",
          field: "status",
          tdClass: "text-center align-middle",
        },
        {
          label: "Grand Total",
          field: "grand_total",
          thClass: "text-nowrap",
          tdClass: "text-center align-middle",
        },
        {
          label: "Costing",
          field: "costing.costing",
          tdClass: "text-center align-middle",
        },
        {
          label: "Profit",
          field: "costing.Outlet_profit",
          tdClass: "text-center align-middle",
        },
        {
          label: "Commission",
          field: "costing.commission",
          tdClass: "text-center align-middle",
        },
      ],
    };
  },
  computed: {
    computeSubtotal() {
      return (qty, price) => qty * price;
    },
  },

  created() {
    this.getallreports();
  },

  methods: {
    async ExportToExcel() {
      this.getallreports();
      const response = await this.$http.get(
        `export_report/master_list/${this.date_start}/${this.date_end}/master`,
        { responseType: "blob" }
      );
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `MasterReports_${this.date_start}-${this.date_end}.xlsx`
      );
      document.body.appendChild(fileLink);

      fileLink.click();
    },

    async getallreports() {
      this.isLoading = true;
      const response = await this.$http.get(
        `report/master_list/${this.date_start}/${this.date_end}`
      );
      this.rows = response.data.data.filter(
        (row) => row.status !== "Payment Failed"
      );
      this.isLoading = false;
    },
    goToOrderSummary(id) {
      this.$router.push({
        name: "order-summary",
        params: {
          id: id,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "src/assets/scss/style.scss";
</style>
